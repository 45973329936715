export const SEO_CONFIG: Partial<Record<string, { title: string; description: string; keywords: string; canonical: string }>> = {
  '/': {
    title: 'Home - CIC | SAP Gold Partner BTP in Egypt | KSA | UAE',
    description: 'Welcome to CIC, a SAP Gold Partner delivering ERP, S/4HANA, FICO, MM, SD, and BTP solutions in Egypt, KSA, and UAE. We empower businesses with innovative cloud technologies, enterprise tools, and SAP systems to foster sustainable growth, operational efficiency, and digital transformation.',
    keywords: 'CIC, SAP, BTP, ERP solutions, S/4HANA, FICO, MM, SD, business growth, cloud technology, digital transformation, SAP Gold Partner, Egypt, KSA, UAE, enterprise solutions',
    canonical: 'https://cic.ae/',
  },
  '/services': {
    title: 'Services - CIC | SAP Gold Partner & BTP in Egypt | KSA | UAE',
    description: 'Explore CIC’s professional services as a SAP Gold Partner, providing ERP, S/4HANA, FICO, MM, SD, and BTP solutions in KSA, Egypt, and UAE. We help businesses achieve innovation, digital transformation, and growth through advanced SAP tools, cloud technology, and industry-specific expertise.',
    keywords: 'SAP services, ERP, S/4HANA, SAP Gold Partner, business innovation, cloud solutions, FICO, MM, SD, SAP solutions, digital transformation, Egypt, KSA, UAE, business growth',
    canonical: 'https://cic.ae/services',
  },
  '/solutions': {
    title: 'Solutions - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in UAE, KSA, and Egypt, we deliver cloud technologies and SAP innovations to transform businesses, boost operational efficiency, and ensure sustainable success across various industries.',
    keywords: 'SAP solutions, ERP, S/4HANA, FICO, MM, SD, cloud technology, BTP, business transformation, SAP Gold Partner, innovation, business efficiency, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions',
  },

  '/partners': {
    title: 'Partners - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'CIC collaborates with global innovators to deliver ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in Egypt, KSA, and UAE, our extensive partner network drives growth, enhances business efficiency, and fosters innovation through advanced SAP tools and technologies.',
    keywords: 'SAP partners, ERP partnerships, SAP Gold Partner, business collaborations, FICO, MM, SD, BTP, growth through partnership, innovation in SAP, Egypt, KSA, UAE, partner network',
    canonical: 'https://cic.ae/partners',
  },
  '/industry': {
    title: 'Industries - CIC | SAP Gold Partner & BTP in Egypt | KSA | UAE',
    description: 'CIC offers industry expertise with ERP, S/4HANA, FICO, MM, SD, and BTP technologies. As a SAP Gold Partner in Egypt, KSA, and UAE, we empower businesses with innovative SAP solutions that enhance efficiency, drive growth, and address specific market challenges with tailored strategies.',
    keywords: 'industry-specific SAP solutions, ERP in industries, S/4HANA, business growth, innovation, tailored SAP solutions, BTP, FICO, MM, SD, Egypt, KSA, UAE, industry expertise',
    canonical: 'https://cic.ae/industry',
  },
  '/about': {
    title: 'About Us - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'Learn about CIC, a SAP Gold Partner serving Egypt, KSA, and UAE. Specializing in ERP, S/4HANA, FICO, MM, SD, and BTP solutions, we empower businesses with advanced SAP tools to drive innovation, improve operational efficiency, and achieve sustainable growth and digital transformation.',
    keywords: 'About CIC, SAP Gold Partner, SAP solutions, ERP in Egypt, KSA, UAE, FICO, MM, SD, innovation, digital transformation, business growth, SAP experts',
    canonical: 'https://cic.ae/about',
  },
  '/contact': {
    title: 'Contact Us - CIC | SAP Gold Partner & BTP in KSA | Egypt | UAE',
    description: 'Reach out to CIC, a SAP Gold Partner specializing in ERP, S/4HANA, FICO, MM, SD, and BTP technologies. Serving Egypt, KSA, and UAE, our team delivers tailored SAP solutions to help businesses achieve operational excellence, digital transformation, and sustainable growth.',
    keywords: 'contact CIC, SAP Gold Partner, ERP solutions, FICO, MM, SD, S/4HANA, BTP, SAP technology, business support, Egypt, KSA, UAE, digital transformation',
    canonical: 'https://cic.ae/contact',
  },
  '/innovation/about': {
    title: 'BTP Business Technology Platform - SAP BTP in Egypt | KSA | UAE',
    description: 'CIC leads innovation as a SAP Gold Partner in Egypt, KSA, and UAE. Our expertise in ERP, S/4HANA, FICO, MM, SD, and BTP technologies empowers businesses to achieve sustainable growth, operational excellence, and market leadership through advanced SAP tools and digital transformation strategies.',
    keywords: 'BTP SAP, Business Technology Platform, innovation in SAP, ERP technologies, S/4HANA, FICO, MM, SD, cloud technology, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/innovation/about',
  },

  '/solutions/obs47zh7fgb99ily0u9ssm9q': {
    title: 'SESMI Solution - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'SESMI revolutionizes service management by providing end-to-end control and visibility across departments, ensuring seamless operations from planning to payment.',
    keywords: 'SESMI, service management, ERP solutions, SAP Gold Partner, BTP, SAP integration, business efficiency, planning to payment, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/obs47zh7fgb99ily0u9ssm9q',
  },

  '/solutions/ufnbqc05nniinsac2ypmy5sn': {
    title: 'XCCRE - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'xCCRE is a cutting-edge application built on SAP’s Real Estate Engine, utilizing the latest Fiori interface technologies for seamless access anytime, anywhere, and on any device.',
    keywords: 'xCCRE, SAP Real Estate Engine, Fiori interface, cloud app, real estate management, SAP Gold Partner, BTP, mobile access, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ufnbqc05nniinsac2ypmy5sn',
  },
  '/solutions/hb03h84pygrh107e0j3klpqd': {
    title: 'Refx for public cloud - CIC | SAP Gold Partner',
    description: 'Refx for Public Cloud is a powerful real estate management application that utilizes SAP’s Fiori design, while operating independently on a local database. This combination offers flexibility and robust functionality, along with a user-friendly interface for improved accessibility.',
    keywords: 'Refx, public cloud, real estate management, SAP Fiori, local database, SAP Gold Partner, cloud solutions, SAP integration, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/hb03h84pygrh107e0j3klpqd',
  },
  '/solutions/amej1rxne2uyvkifh8ixa10e': {
    title: 'HCM for Public Sector - CIC | SAP Gold Partner',
    description: 'The HCM for Public Sector solution is designed to streamline human resources processes, ensuring effective management of personnel within government and public sector organizations. It focuses on organizing internal structures, monitoring performance, and maintaining up-to-date employee records.',
    keywords: 'HCM public sector, human resources, government HR solution, SAP Gold Partner, personnel management, HR automation, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/amej1rxne2uyvkifh8ixa10e',
  },
  '/solutions/lw25xjkq4be1x3edtqf3ybic': {
    title: 'Retirement Fund - CIC | SAP Gold Partner',
    description: 'The Retirement Fund Solution simplifies and automates end-of-service processes, improving efficiency, accuracy, and transparency, making it an essential tool for managing employee benefits.',
    keywords: 'retirement fund solution, employee benefits, SAP Gold Partner, service automation, end-of-service processes, employee management, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/lw25xjkq4be1x3edtqf3ybic',
  },
  '/solutions/sub35wzrre6xv6yh3j3tyt6j': {
    title: 'Sales Tax for Egypt - CIC | SAP Gold Partner & BTP',
    description: 'Our Sales Tax solution for Egypt is tailored to meet local regulations, ensuring compliance with tax laws, efficient reporting, and streamlined accounting processes for businesses.',
    keywords: 'Sales Tax Egypt, tax solution, SAP Gold Partner, local tax compliance, accounting processes, Egypt business solutions, SAP integration',
    canonical: 'https://cic.ae/solutions/sub35wzrre6xv6yh3j3tyt6j',
  },
  '/solutions/jb5pg8iwqxaqyqrillidz0cm': {
    title: 'SESMI SD (Payment Application) - CIC | SAP Gold Partner',
    description: 'An advanced add-on for SAP ERP, designed to enhance the management of customer service entry sheets. It offers an efficient way to track and manage the progress of on-site work as maintained by the site engineer, ensuring seamless integration with various departments for accurate project tracking and billing.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/jb5pg8iwqxaqyqrillidz0cm',
  },
  '/solutions/at82vcokxuk75xofjh9rdhfo': {
    title: 'Capex Approval App - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. The Capex Approval App streamlines the capital expenditure process by automating the creation, approval, and tracking of DNs',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/at82vcokxuk75xofjh9rdhfo',
  },
  '/solutions/krlikbzb5b1gkprw3iubjrbr': {
    title: 'Customer Credit App - CIC | SAP Gold Partner',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. This solution optimizes credit management by enhancing transparency, operational control, and financial oversight.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/krlikbzb5b1gkprw3iubjrbr',
  },
  '/solutions/xekwmgq4s42lnu3n9ntddclk': {
    title: 'Collection Forecast - CIC | SAP Gold Partner',
    description: 'The Collection Forecast tool uses AI-driven analytics to project cash flow, monitor customer payment behaviors, and enhance financial planning in the real estate sector.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/xekwmgq4s42lnu3n9ntddclk',
  },
  '/solutions/mgeabpkzgy651hjvwx4eassz': {
    title: 'Bank ReconMan - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'A cutting-edge solution designed to simplify and enhance bank reconciliation processes through automation and machine learning.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/mgeabpkzgy651hjvwx4eassz',
  },
  '/solutions/qexgemgf11j940wa7huvcntg': {
    title: 'ISPIR Solution - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'An integrated, internet-based solution designed for online spare parts data collection, review, and approval, leveraging the electronic version of the Spare Parts Interchangeability Record (SPIR).',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/qexgemgf11j940wa7huvcntg',
  },
  '/solutions/txof867dyyhx479sntxxzwhi': {
    title: 'AI-Powered Language Solutions & Gen AI - CIC | SAP Gold Partner',
    description: 'This Generative AI in SAP BTP solution leverages powerful AI technologies to drive smarter business decisions, enhance customer interactions, and provide real-time insights—helping companies stay ahead in an ever-evolving market.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/txof867dyyhx479sntxxzwhi',
  },
  '/solutions/vggi7zhtepqubei1zh3vvpgk': {
    title: 'Estimation Solution - CIC | SAP Gold Partner in KSA | Egypt',
    description: 'A cornerstone of effective project management and financial planning. It ensures that projects stay on track and within budget by accurately forecasting costs, resources, and timelines.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/vggi7zhtepqubei1zh3vvpgk',
  },
  '/solutions/dn8q211r4ffjm5j0yznn8luc': {
    title: 'Sourcing MOD (Tendering) - CIC | SAP Gold Partner in UAE',
    description: 'Tendering is a formal procurement process where contractors, suppliers, or external consultants are invited by official entities or companies to submit bids for goods, services, or works. The aim is to select the best bidder based on pre-determined criteria.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dn8q211r4ffjm5j0yznn8luc',
  },
  '/solutions/xo0dmn0dxibl0r21hmdz2h5m': {
    title: 'DBM Enhancement Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'This enhancement helps businesses optimize their vehicle purchasing process, improve planning accuracy, and streamline cost management.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/xo0dmn0dxibl0r21hmdz2h5m',
  },
  '/solutions/dwgpo4nwkr6uozstpgeisubd': {
    title: 'AR/AP Made Easy Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'CIC’s AR/AP Automation Solution simplifies and accelerates accounts receivable (AR) and accounts payable (AP) processes, providing a seamless experience across web, tablet, and mobile platforms.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dwgpo4nwkr6uozstpgeisubd',
  },
  '/solutions/dn7sflqkkuwpicy3sua6aayz': {
    title: 'Cash Budget Solution - CIC | SAP Gold Partner in UAE | KSA | Egypt',
    description: 'CIC’s Cash Budget Solution streamlines budget management, offering organizations full control over planning, approval, and consumption through intuitive features and tools.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dn7sflqkkuwpicy3sua6aayz',
  },
  '/solutions/m0ke0pxp9mesr6k9mayh55ut': {
    title: 'Project Control Boardroom - SAP Gold Partner in KSA | UAE | Egypt',
    description: 'Our reporting solution delivers dynamic insights into essential business metrics such as sales, revenue, payment plans, and Built-Up Area (BUA) through intuitive visualizations like charts and pie charts. These tools empower users to quickly identify trends and performance, supporting data-driven decision-making.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/m0ke0pxp9mesr6k9mayh55ut',
  },
  '/solutions/avca6gbv67e964vu4opxao4v': {
    title: 'Primavera Integration Add-On Solution - CIC | SAP Gold Partner',
    description: 'The Primavera Integration Add-On for SAP enhances project management capabilities by seamlessly connecting SAP with Primavera.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/avca6gbv67e964vu4opxao4v',
  },
'/solutions/lpxxff9la12t84ali0ehjgf3': {
    title: 'Project Portfolio Management Solution - CIC | SAP Gold Partner',
    description: 'CIC’s PPM (Project Portfolio Management) solution simplifies and optimizes project and portfolio management by providing a comprehensive, automated platform for all project phases.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/lpxxff9la12t84ali0ehjgf3',
  },
  '/solutions/fyimby0thg5sicwmtxbkwxep': {
    title: 'E-Service Solution - CIC | SAP Gold Partner in Egypt | KSA | UAE',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions.Digital platforms have redefined customer service in the real estate industry',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/fyimby0thg5sicwmtxbkwxep',
  },
  '/solutions/ljfyxk8fc0j5xcegiee5u6xn': {
    title: 'RE Mortage (NUCA) - CIC | SAP Gold Partner',
    description: 'A comprehensive web application designed to streamline long-term financing for real estate units. It delivers a seamless and efficient experience for both applicants and administrators, combining advanced features with user-centric design.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ljfyxk8fc0j5xcegiee5u6xn',
  },
  '/solutions/u5q62wva92ay5cy6ollssgn0': {
    title: 'VSO Solution - CIC | SAP Gold Partner & BTP in UAE | KSA | Egypt',
    description: 'VSO is a cutting-edge e-commerce platform designed to enhance the real estate sales process. Its user-friendly interface, secure payment options, and seamless ERP integration make it the ideal tool for businesses and developers to optimize sales and customer engagement.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/u5q62wva92ay5cy6ollssgn0',
  },
  '/solutions/ckw421i79pc3vlxmclwsa9ve': {
    title: 'Mobile Van Sales - CIC | SAP Gold Partner in UAE',
    description: 'Our solution empowers mobile sales teams to manage all tour-related activities seamlessly from their mobile devices, streamlining daily tasks and enhancing efficiency for drivers on the go.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/ckw421i79pc3vlxmclwsa9ve',
  },
  '/solutions/n7oa8ubp17ardrjdfsk7bg04': {
    title: 'Mobile Inventory And Warehouse Management - SAP Gold Partner',
    description: 'Transform your warehouse operations with real-time insights and mobile convenience. Boost efficiency, increase productivity, and bring accuracy to the forefront of your business with our integrated mobile solution.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/n7oa8ubp17ardrjdfsk7bg04',
  },
'/solutions/dgqrwo5lkgmy18f73uj94fsf': {
    title: 'ManPower Supply - CIC | SAP Gold Partner in Egypt',
    description: 'Discover CIC’s tailored ERP, S/4HANA, FICO, MM, SD, and BTP solutions. As a SAP Gold Partner in UAE, KSA, and Egypt, we deliver cloud technologies and SAP innovations to transform businesses, boost operational efficiency, and ensure sustainable success across various industries.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/dgqrwo5lkgmy18f73uj94fsf',
  },
  '/solutions/v9c39fyaf5txufxndne5dm45': {
    title: 'E-Invoicing (KSA) - CIC | SAP Gold Partner & BTP in Saudi Arabia',
    description: 'Our Solution manages the entire manpower cycle—from initial inquiry to billing—by automating, optimizing, and simplifying operations.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/v9c39fyaf5txufxndne5dm45',
  },
  '/solutions/i85azk6n1f2ugjsnvo7grgqb': {
    title: 'E-Invoicing (Egypt) - CIC | SAP Gold Partner in UAE',
    description: 'With CIC’s E-Invoicing Solution, your business can confidently meet all regulatory requirements while enjoying a secure, efficient, and hassle-free invoicing process.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/i85azk6n1f2ugjsnvo7grgqb',
  },
  '/solutions/k9db3n65u3t4rvwkj5hrmzo1': {
    title: 'Customer Payment Portal (City pay) - SAP Gold Partner',
    description: 'Transform your payment process with CIC’s Customer Payment Portal—making payments easy, secure, and efficient while delivering a superior user experience.',
    keywords: 'CIC, SAP, BTP, ERP, S/4HANA, FICO, MM, SD, Solutions, SAP Gold Partner, Cloud Solutions, Innovation, Growth, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/solutions/k9db3n65u3t4rvwkj5hrmzo1',
  },
  '/services/eq3sa7phyp77ieaeqzfsrrqc': {
    title: 'Scoping & Case Studies Service - SAP Gold Partner',
    description: 'CIC helps businesses unlock project potential by identifying key objectives, defining project scopes, and delivering actionable insights through in-depth case studies.',
    keywords: 'SAP Services, SAP Gold Partner, SAP Consulting, Case Studies, Project Scoping, SAP Solutions, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/eq3sa7phyp77ieaeqzfsrrqc',
  },
  '/services/e13zmikshyeeinw14se4w2h7': {
    title: 'Business Process Design Service - SAP Gold Partner',
    description: 'Optimize your workflows with processes that are flexible, efficient, and compliant with global standards, helping your organization achieve operational excellence.',
    keywords: 'SAP Business Process, SAP Gold Partner, Process Design, SAP Workflow Optimization, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/e13zmikshyeeinw14se4w2h7',
  },
  '/services/lfa1vlh5n3ry83elnsau0rca': {
    title: 'Technical Architecture & Design Service - SAP Gold Partner',
    description: 'Ensure seamless integration and operational efficiency with expertly designed technical architectures tailored to meet the demands of modern businesses.',
    keywords: 'Technical Architecture, SAP Integration, SAP Design, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/lfa1vlh5n3ry83elnsau0rca',
  },
  '/services/e01543tz64uqb1d1sp7ofkml': {
    title: 'Program & Project Management Service - SAP Gold Partner',
    description: 'Achieve project goals efficiently with CIC’s expert project management practices, designed to deliver results on time and within budget.',
    keywords: 'SAP Project Management, SAP Program Management, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/e01543tz64uqb1d1sp7ofkml',
  },
  '/services/mvry36fz55rkcj03uh22a28p': {
    title: 'Composition & Application Development Service - SAP Gold Partner',
    description: 'Streamline operations with advanced applications designed to enhance user experience and unify disparate systems into a cohesive, efficient ecosystem.',
    keywords: 'SAP Application Development, SAP Solutions, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/mvry36fz55rkcj03uh22a28p',
  },
  '/services/lw39t6hni2y4jlt05lzrb6bh': {
    title: 'Research & Development Service - SAP Gold Partner',
    description: 'Stay ahead of the curve with innovative solutions tailored to meet evolving industry demands, backed by CIC’s dedicated R&D expertise.',
    keywords: 'SAP R&D, Innovation, SAP Gold Partner, SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/lw39t6hni2y4jlt05lzrb6bh',
  },
  '/services/ltujsch0gcapvqp61ofrveq8': {
    title: 'Business Objects Solutions Service - SAP Gold Partner',
    description: 'Leverage advanced Business Intelligence, Enterprise Performance Management, and Governance, Risk, and Compliance solutions to make informed decisions and drive organizational success.',
    keywords: 'SAP Business Objects, SAP Solutions, BI, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/ltujsch0gcapvqp61ofrveq8',
  },
  '/services/opjqto8senvl3t287hpfio89': {
    title: 'SAP All-In-One Solutions Service - SAP Gold Partner',
    description: 'Simplify your operations with scalable, end-to-end solutions tailored for small and midsize businesses, ensuring cost efficiency and operational excellence.',
    keywords: 'SAP All-In-One, SAP SMB Solutions, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/opjqto8senvl3t287hpfio89',
  },
  '/services/mmqcyn36v7ws3i56jai8aazx': {
    title: 'Cloud Solutions Service - SAP Gold Partner',
    description: 'Unlock the potential of the cloud with scalable, agile solutions tailored to your business needs, ensuring seamless integration and future-ready capabilities.',
    keywords: 'SAP Cloud Solutions, SAP Gold Partner, Cloud Integration, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/mmqcyn36v7ws3i56jai8aazx',
  },
  '/services/d6pwwt8373q2bwvniejxgonm': {
    title: 'SAP Consulting Services - SAP Gold Partner',
    description: 'Maximize your SAP investment with expert consulting services designed to align technology with your business goals, ensuring seamless implementation and optimized performance.',
    keywords: 'SAP Consulting, SAP Gold Partner, SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/d6pwwt8373q2bwvniejxgonm',
  },
  '/services/jae6zsfrmb45ytdy6xictnn9': {
    title: 'Industry Consulting Service - SAP Gold Partner',
    description: 'Leverage CIC’s sector-specific expertise to address unique challenges, adopt proven best practices, and unlock new growth opportunities.',
    keywords: 'Industry Consulting, SAP Gold Partner, SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/jae6zsfrmb45ytdy6xictnn9',
  },
  '/services/la1xhyivcvh58qudoygozd7i': {
    title: 'Roll-Out & Localization Services - SAP Gold Partner',
    description: 'Achieve successful SAP roll-outs across diverse regions with CIC’s blend of global methodologies and localized expertise tailored to meet specific market needs.',
    keywords: 'SAP Roll-Out, Localization, SAP Gold Partner, SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/la1xhyivcvh58qudoygozd7i',
  },
  '/services/nxzs8agx5ccaq5685qhqze10': {
    title: 'Run SAP Service - SAP Gold Partner',
    description: 'Achieve enhanced efficiency and performance in your SAP environment through CIC’s proven Run SAP methodology, designed to ensure the long-term success and scalability of your SAP systems.',
    keywords: 'Run SAP, SAP Gold Partner, SAP Performance, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/nxzs8agx5ccaq5685qhqze10',
  },
  '/services/brifk03jt1b4iqpm39haj752': {
    title: 'Upgrade Service - SAP Gold Partner',
    description: 'Unlock the full potential of the latest SAP features and technologies with CIC’s expert upgrade services, designed to provide smooth, efficient, and hassle-free migrations.',
    keywords: 'SAP Upgrade, SAP Migration, SAP Gold Partner, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/brifk03jt1b4iqpm39haj752',
  },
  '/services/j3uw3so9x8304rd3y9abatni': {
    title: 'Premium Support Offerings Service - SAP Gold Partner',
    description: 'Experience consistent and expert support with CIC’s Premium Support offerings, designed to provide comprehensive, responsive, and proactive assistance for your SAP systems.',
    keywords: 'SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/services/j3uw3so9x8304rd3y9abatni',
  },
  
// 

'/partners/Better-Home': {
    title: 'Better Home - SAP Gold Partner',
    description: 'Through collaboration and a commitment to excellence, we help our clients achieve their goals and succeed in a rapidly evolving market.',
    keywords: 'BetterHome, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Better-Home',
  },
  '/partners/HAPCO': {
    title: 'HAPCO - CIC | SAP Gold Partner',
    description: 'HAPCO has been a responsible company committed to excellence at work in both residential and industrial electro-mechanical projects, which has secured the trust of its clients.',
    keywords: 'HAPCO, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/HAPCO',
  },
    '/partners/Egyptian-General-Petroleum-Corporation-EGPC': {
    title: 'Egyptian General Petroleum Corporation EGPC | SAP Gold Partner',
    description: 'Established in 1956 and headquartered in Cairo, is Egypts national oil company. Initially named the General Petroleum Authority, it was rebranded as EGPC in 1962. Operating under the Ministry of Petroleum, EGPC is integral to Egypts oil and gas industry.',
    keywords: 'Egyptian General Petroleum Corporation, EGPC, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Egyptian-General-Petroleum-Corporation-EGPC',
  },
  '/partners/Central-Bank-of-Libya': {
    title: 'Capital Bank of Libya | CIC - SAP Gold Partner',
    description: 'The Central Bank of Libya is a state-owned institution responsible for monetary stability and economic growth under national policy.',
    keywords: 'Capital Bank of Libya, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Central-Bank-of-Libya',
  },
  '/partners/Halawani-Exchange': {
    title: 'Halwani Exchange | CIC - SAP Gold Partner',
    description: 'A financial services provider offering money transfer, foreign exchange, and remittance services',
    keywords: 'Halwani Exchange, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Halawani-Exchange',
  },
  '/partners/The-American-University-in-Cairo': {
    title: 'American University in Cairo | CIC - SAP Gold Partner',
    description: 'A private research university located in New Cairo, Egypt. Established in 1919, AUC offers American-style liberal arts education at the undergraduate, graduate, and professional levels.',
    keywords: 'American University in Cairo, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/The-American-University-in-Cairo',
  },
  '/partners/Tetra-Pak': {
    title: 'Tetra Pak | CIC - SAP Gold Partner',
    description: 'A Swedish multinational company specializing in the design and manufacturing of processing and packaging solutions for the food and beverage industry.',
    keywords: 'Tetra Pak, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Tetra-Pak',
  },
  '/partners/Adidas': {
    title: 'Adidas | CIC - SAP Gold Partner',
    description: 'A globally recognized German multinational corporation specializing in the design and manufacturing of sportswear, footwear, and accessories.',
    keywords: 'Adidas, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Adidas',
  },
  '/partners/Pirelli': {
    title: 'Pirelli | CIC - SAP Gold Partner',
    description: 'Pirelli Founded in 1872, Pirelli is a company with deep Italian roots, a brand that is known around the world for its avantgarde technology, capacity for innovation and the quality of its products.',
    keywords: 'Pirelli, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Pirelli',
  },
  '/partners/BEST-Al-Yousifi': {
    title: 'Best Al Yousifi | CIC - SAP Gold Partner',
    description: 'A leading electronics retailer in Kuwait, offering a wide range of consumer electronics, including kitchen appliances, mobile phones, computers, gaming consoles, and home entertainment systems.',
    keywords: 'Best Al Yousifi, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/BEST-Al-Yousifi',
  },
  '/partners/Mubadala': {
    title: 'Mubadala | CIC - SAP Gold Partner',
    description: 'A sovereign wealth fund owned by the government of Abu Dhabi, United Arab Emirates (UAE). Established in 2017 through the merger of the Mubadala Development Company and the International Petroleum Investment Company (IPIC), Mubadala manages a diverse portfolio of assets both within the UAE and internationally.',
    keywords: 'Mubadala, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Mubadala',
  },
  '/partners/Universal-Group': {
    title: 'Universal Egypt | CIC - SAP Gold Partner',
    description: 'A prominent Egyptian company specializing in the manufacturing and distribution of home appliances. Established in 1980, Universal has become a household name in Egypt, offering a diverse range of products designed to enhance daily living.',
    keywords: 'Universal Egypt, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Universal-Group',
  },
  // 
  '/partners/talaatmoustafa': {
    title: 'Talaat Moustafa Group | CIC - SAP Gold Partner Egypt - KSA - UAE',
    description: 'TMG is a leading conglomerate specialized in real estate development and has outstanding track record of creating large, vibrant, diverse communities and hospitality developments, providing high-quality experiences accompanied by superb amenities. TMG stands as an industry giant, presenting world-class developments across real estate asset classes.',
    keywords: 'Talaat Moustafa Group, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/talaatmoustafa',
  },
'/partners/EDSCO': {
    title: 'EDSCO | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'East Delta Saudi Co. Ltd. provides its clients with a full-scale set up through a highly professional team with expertise in engineering, procurement, and construction (EPC), civil-, electrical-, and mechanical works as well as fabrications, renovations, repairs, maintenance, finishing, landscaping, and interior design.',
    keywords: 'EDSCO, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/EDSCO',
  },

'/partners/new-giza': {
    title: 'New Giza | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Is a prominent real estate development company in Egypt, established in 2006, Located at 22km on the Cairo-Alexandria Desert Road, the toll station is only 7km away. The 6th of October main road provides instant access. Meanwhile the Mehwar is 2km away.',
    keywords: 'new giza, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/new-giza',
  },
'/partners/Haidylena': {
    title: 'Haidylena | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Founded in 1990, is a leading Egyptian manufacturer of hemodialysis products and medical disposables for Egypt and the Middle East.',
    keywords: 'Haidylena, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Haidylena',
  },
'/partners/Spinneys': {
    title: 'Spinneys | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'a prominent supermarket chain, known for offering a wide range of high-quality products, including fresh produce, groceries, and specialty items. The company has established a strong presence in countries such as the United Arab Emirates (UAE), Lebanon, Egypt, Qatar, and Oman.',
    keywords: 'Spinneys, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Spinneys',
  },
'/partners/Bagader-Trading-East': {
    title: 'Bagader Trading East | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'A prominent wholesaler and distributor of tyres, tubes, and batteries, operating primarily in the Middle East, CIS countries, Africa, Afghanistan, and Pakistan. Established in the early 1960s in Saudi Arabia, the company has expanded its operations to Dubai, Yemen, and Beirut.',
    keywords: 'Bagader Trading East, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Bagader-Trading-East',
  },
'/partners/JAL-International': {
    title: 'JAL International | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'A Saudi Arabian company established in 1985, specializing in plant maintenance, construction, and manufacturing services. The company has significantly contributed to Saudi Arabia industrial and infrastructural development, offering world-class operational, maintenance, technical.',
    keywords: 'JAL International, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/JAL-International',
  },
'/partners/Misr-Italia-Properties': {
    title: 'Misr Italia Group | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'a leading real estate developer in Egypt, with over 25 years of experience in the industry. The company has a proven track record of delivering high-quality projects, and its developments are known for their innovative designs, luxurious finishes, and prime locations.',
    keywords: 'Misr Italia Group, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Misr-Italia-Properties',
  },
'/partners/Memaar-Al-Morshedy': {
    title: 'Memaar AlMorshedy | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Established in 1983 by Mohamed Morshedy, is a prominent Egyptian real estate development company. The company has recently rebranded as Morshedy Group, reflecting its evolution and diversification into sectors such as healthcare, education, and entertainment. MORSHEDY GROUP',
    keywords: 'Memaar AlMorshedy, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Memaar-Al-Morshedy',
  },

'/partners/IWAN-Development': {
    title: 'IWAN Development | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Is a leading Egyptian real estate company established in 2004, renowned for creating integrated residential and commercial communities that blend luxury with functionality.',
    keywords: 'IWAN Development, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/IWAN-Development',
  },
'/partners/Madinet-Masr': {
    title: 'Madinet Masr | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'One of Egypt leading real estate development companies. Established in 1959, it initially focused on developing large-scale urban projects to address Cairo housing needs. Over time, MNHD has evolved into a prominent developer of mixed-use, residential, and commercial communities.',
    keywords: 'Madinet Masr, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Madinet-Masr',
  },

'/partners/Al-Rabwa': {
    title: 'Al Rabwa | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Is a high-end residential compound located in the prestigious Sheikh Zayed area in Giza, Egypt. Developed by Talaat Moustafa Group, it is a landmark development offering a luxurious lifestyle in a serene environment.',
    keywords: 'Al Rabwa, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Al-Rabwa',
  },
'/partners/San-Stefano': {
    title: 'San Stefano | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Is a prominent mixed-use complex located in the San Stefano neighborhood of Alexandria, Egypt. The complex includes a luxury hotel, residential apartments, offices, and the San Stefano Mall.',
    keywords: 'San Stefano, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/San-Stefano',
  },
'/partners/ABB': {
    title: 'ABB | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Is a global technology leader specializing in electrification and automation solutions. The company offers a comprehensive range of products and services designed to enhance industrial performance, improve energy efficiency, and promote sustainability.',
    keywords: 'ABB, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/ABB',
  },
'/partners/Daem-Real-Estate-Investment': {
    title: 'Daem Real Estate Investment Co | SAP Gold Partner - Egypt - KSA',
    description: 'Is a Saudi Arabian real estate development and investment company. It serves as the real estate arm of Mohammed Abdulaziz Al Rajhi & Sons Investment Company.',
    keywords: 'Daem Real Estate Investment, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Daem-Real-Estate-Investment',
  },
'/partners/SITML': {
    title: 'SITML | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'a Saudi-based company specializing in trading and marketing products from around the world. With branches across the Kingdom and its head office in Jeddah, SITML serves as a holding company for various enterprises.',
    keywords: 'Daem Real Estate Investment, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/SITML',
  },
  '/partners/ABYAAR': {
    title: 'ABYAAR | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Abyaar Real Estate Development Company K.S.C.P. is a prominent real estate developer operating across the Middle East, specializing in residential, commercial, retail, and hospitality projects. Established in Kuwait, Abyaar has expanded its operations to include developments in the United Arab Emirates and Egypt.',
    keywords: 'ABYAAR, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/ABYAAR',
  },
  '/partners/Al-Sayed-Group': {
    title: 'Al Sayed Group | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'In 1979 , Abdullah Alsayed Company and his partners for contracting has set its first steps. From then on, it continued to flourish starting from one city until it covers the most of the Kingdom cities.',
    keywords: 'Al Sayed Group, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Al-Sayed-Group',
  },
  '/partners/Seraa-Group': {
    title: 'SERAA GROUP | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'A diversified business conglomerate based in Saudi Arabia, established in 2004, with its headquarters in Madinah Al Munawwarah.',
    keywords: 'SERAA GROUP, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Seraa-Group',
  },
  '/partners/RANCO': {
    title: 'RANCO | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Established in 1950 in Khobar City, Rabiah & Nassar Group started as a small road-building contractor and worked in concert with the Arabian American Oil Company (Aramco) and municipalities to construct some of the major road networks in the Eastern Province. From these humble beginnings',
    keywords: 'RANCO, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/RANCO',
  },
  '/partners/SHAR': {
    title: 'SHAR | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'A beacon of innovation in the Saudi Arabian construction sector, was founded in 1397 H (1977 AD) by the esteemed Sheikh Hammad bin Saleem Albalawi may his soul rest in peace.',
    keywords: 'SHAR, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/SHAR',
  },
  '/partners/Al-oula': {
    title: 'Al Oula | CIC - SAP Gold Partner - Egypt - KSA - UAE',
    description: 'Established in 2002, this company is a joint venture between Al Muhaidib Group and Al Fozan Group. It offers comprehensive services throughout the real estate development lifecycle, including market research, development feasibility, master planning, project and construction management, and marketing and sales.',
    keywords: 'Al Oula, SAP Support, SAP Gold Partner, Premium SAP Services, Egypt, KSA, UAE',
    canonical: 'https://cic.ae/partners/Al-oula',
  },
  
};
